
  import {defineComponent, ref, computed } from "vue"
  import { getDataFromPointer, schemaPointerToDataPointer } from "../JsonSchema/path-handler"
  import jsonpointer from "jsonpointer"
  import { getForm, setElement } from "@/components/shared/form/form-provider"

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    components: {
      // load at runtime to avoid circular dependency https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
      FormElement: () => import('./FormElement.vue'),
    },
    props: {
      schema: {
        type: Object,
        required: true
      },
      uiSchema: {
        type: Object,
        required: true
      },
      pointer: {
        type: String,
        required: true
      },
      widget: {
        type: [String, Function]
      },
      title: {
        type: String
      },
      required: {
        type: Boolean,
        default: false
      },
      parentScope: {
        type: String,
        required: true
      },
      options: {
        type: Object
      },
    },
    setup(props, { emit }) {
      const form = getForm()

      const addElement = () => {
        const currentValue = enteredValue.value ? [...enteredValue.value] : []
        switch (props.uiSchema.type) {
          case 'string':
            currentValue.push("")
            break
          case 'object':
            currentValue.push({})
            break
          case 'array':
            currentValue.push([])
            break
          case 'number':
          case 'integer':
            currentValue.push(0)
            break
          case 'boolean':
            currentValue.push(false)
            break
          default:
            break
        }
        enteredValue.value = currentValue
      }

      const removeElement = (position: number) => {
        if (!enteredValue.value) return
        const currentValue = [...enteredValue.value]
        currentValue.splice(position, 1)
        enteredValue.value = currentValue
      }

      const enteredValue = computed({
        get: () => jsonpointer.get(form.value, props.parentScope),
        set: (value) => setElement(props.parentScope, value)
      })

      return {
        addElement,
        removeElement,
        enteredValue
      }
    }
  })
